import React, { useEffect, useState } from "react";
import FileMultiUpload from "../FileUploader/FileMultiUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../Loading";
import axios from "axios";
import FileUpload from "../FileUploader/FileUpload";
import FileItem from "../FileUploader/FileItem";
import NetworkError from "./NetworkError";
import Swal from "sweetalert2";
function NewNews({ setShowModal, activities, setActivities }) {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    titleAr: "",
    titleEn: "",
    category: "",
  });

  const [descAr, setDescAr] = useState("");

  const [descEn, setDescEn] = useState("");

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  //Slider Files
  const [activitySlider, setActivitySlider] = useState([]);
  const [activitySliderData, setActivitySliderData] = useState([]);
  const removeMainFile = (filename, index) => {
    setActivitySlider(activitySlider.filter((file, i) => i !== index));
    setActivitySliderData(
      activitySliderData.filter((fileData, i) => i !== index)
    );
  };

  const submitActivity = async (e) => {
    e.preventDefault();
    if (
      values.titleAr !== "" &&
      values.titleEn !== "" &&
      values.category !== "" &&
      descAr !== "" &&
      descEn !== "" &&
      activitySlider.length !== 0
    ) {
      setError(null);
      setLoading(true);
      uploadImages();
    }
  };

  const uploadImages = async () => {
    try {
      for (let i = 0; i < activitySlider.length; i++) {
        await uploadImage(activitySlider[i]);
      }
      await setImageUrls(images);
    } catch (err) {
      console.log(err);
    }
  };

  const [imageUrls, setImageUrls] = useState([]);
  var images = [];
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}/cooks/upload/cook-image`,
        formData
      )
      .then((imgResponse) => {
        const imageUrl = imgResponse.data.imagePath;
        images = [...images, imageUrl];
      })
      .catch((err) => {
        if (err.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  useEffect(() => {
    if (imageUrls.length !== 0) {
      completeUpload();
    }
  }, [imageUrls]);

  const completeUpload = async () => {
    axios
      .post(`${process.env.REACT_APP_API_PATH}/activities/create`, {
        title_en: values.titleEn,
        title_ar: values.titleAr,
        category: values.category,
        description_en: descEn,
        description_ar: descAr,
        image_url: imageUrls,
        video_url: values.videoLink,
        isvideo: true,
      })
      .then(function (response) {
        setLoading(false);
        setActivities([...activities, response.data]);
        setShowModal(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[85%]">
          <form onSubmit={submitActivity}>
            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between h-[50px]  rounded-t">
                <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                  Add New Activity
                </div>
                <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
              </div>
              {/* {body} */}
              <div className="relative px-6 w-full">
                <div className="text-[#0388CC] font-[600] text-[14px]">
                  Basic Information
                </div>
                <div className="flex flex-wrap">
                  <div className="w-[40%] mr-[20px] my-[10px]">
                    <label
                      className="text-[14px] font-[500]"
                      htmlFor="title-en"
                    >
                      Title - EN{" "}
                      <span className="text-[red] font-[700]">*</span>
                    </label>
                    <input
                      required
                      className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                      placeholder="Activity title in english"
                      onChange={changeHandler}
                      value={values.titleEn}
                      name="titleEn"
                      type="text"
                      id="title-en"
                    />
                  </div>
                  <div className="w-[40%] mr-[20px] my-[10px]">
                    <label
                      className="text-[14px] font-[500]"
                      htmlFor="title-ar"
                    >
                      Title - AR{" "}
                      <span className="text-[red] font-[700]">*</span>
                    </label>
                    <input
                      required
                      className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                      placeholder="Activity title in arabic"
                      onChange={changeHandler}
                      value={values.titleAr}
                      name="titleAr"
                      type="text"
                      id="title-ar"
                    />
                  </div>
                  <div className="w-[400px] mr-[120px] my-[10px]">
                    <div className="mt-[0px] text-[15px] font-[400] text-[#000]">
                      Category
                      <span className="text-[red] font-[700]">*</span>
                    </div>
                    <input
                      required
                      className="mr-[10px]"
                      type="radio"
                      name="category"
                      onChange={() => {
                        setValues({ ...values, category: "national" });
                      }}
                      value="national"
                      id="national"
                    />

                    <label
                      htmlFor="international"
                      className="text-[14px] font-arabic text-[#0388CC] font-[400]"
                    >
                      معارض عالمية
                    </label>
                    <input
                      required
                      className="mr-[10px] ml-[30px]"
                      type="radio"
                      onChange={() => {
                        setValues({ ...values, category: "international" });
                      }}
                      name="category"
                      value="international"
                      id="international"
                    />
                    <label
                      htmlFor="national"
                      className="text-[14px] font-arabic text-[#0388CC] font-[400]"
                    >
                      معارض سورية
                    </label>
                  </div>
                </div>
                <div className="flex flex-wrap mt-[15px] w-[87%]">
                  <RichTextEditor
                    name="Description-EN"
                    initialValue=""
                    getValue={setDescEn}
                  />
                  <RichTextEditor
                    name="Description-AR"
                    initialValue=""
                    getValue={setDescAr}
                  />
                </div>
                <div className="my-12">
                  <p className="text-[#000] text-[14px] font-[400]">
                    Select a images of Product ( JPEG or PNG ):
                    <span className="text-[red] font-[700]">*</span>
                  </p>
                  <div className=" w-[130px] h-[30px] mt-[10px]">
                    <FileMultiUpload
                      required={true}
                      files={activitySlider}
                      filesData={activitySliderData}
                      setFilesData={setActivitySliderData}
                      setFiles={setActivitySlider}
                      removeFile={removeMainFile}
                    />
                  </div>
                  <div>
                    <FileList
                      files={activitySlider}
                      filesData={activitySliderData}
                      removeFile={removeMainFile}
                    />
                  </div>
                  <div className="text-[#000] mt-[20px] text-[14px] font-[400]">
                    Video Link for the main Slider
                  </div>
                  <input
                    className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                    placeholder="or Copy URL for the Video Here"
                    onChange={changeHandler}
                    value={values.videoLink}
                    name="videoLink"
                    type="url"
                  />
                </div>
                {error ? (
                  <p className="text-[14px] ml-4 font-[400] text-[red]">
                    <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                  </p>
                ) : null}
              </div>
              {loading ? <Loading /> : null}
              {showError ? <NetworkError setShowModal={setShowError} /> : null}
              {/*footer*/}
              <div className="flex items-center justify-end px-3 rounded-b">
                <div className=" flex pb-[10px] ">
                  <div
                    onClick={() => setShowModal(false)}
                    className="rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                  >
                    Cancel
                  </div>
                  <input
                    type="submit"
                    className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                    value="Save"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default NewNews;
