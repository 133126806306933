import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import "./FileUpload.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
const FileUpload = ({
  files,
  setFiles,
  removeFile,
  setFileData,
  setDatabaseFile,
  type,
  disabled2,
  required
}) => {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);
  const uploadHandler = (event) => {
    if (setDatabaseFile) {
      setDatabaseFile([]);
    }
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;
    setFiles([file]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setFileData([reader.result]);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <div className="file-card w-full h-full">
        <div className="file-inputs">
          <input
            required={required}
            accept={!type ? "image/*" : type}
            disabled={disabled || disabled2}
            onClick={(event) => {
              event.target.value = null;
            }}
            type="file"
            onChange={uploadHandler}
          />
          <button>
            <i>
              <FontAwesomeIcon icon={faArrowUpFromBracket} />
            </i>
            Upload
          </button>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
