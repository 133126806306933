import React, { useState, useEffect } from "react";
import { faBell, faComment, faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/productByCategoryColumns";
import { selectCategory } from "../../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import ApprovalSelector from "../ApprovalSelector";
import { Link } from "react-router-dom";
import { setProduct } from "../../redux/slices/itemSlice";
import {
  faCaretDown,
  faCirclePlus,
  faGear,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NewProductToCategory from "../Modals/NewProductToCategory";
import DeleteWarning from "../Modals/DeleteWarning";
import { selectSearch } from "../../redux/slices/itemSlice";
import { setSearch } from "../../redux/slices/itemSlice";
function ProductsByCategory() {
  const searchInput = useSelector(selectSearch);
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectCategory);
  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const [showModal, setShowModal] = React.useState(false);
  const [rowHeight, setRowHeight] = useState(60);
  const showCellBorders = false;
  const showZebraRows = false;

  const [products, setProducts] = useState([]);
  var data = [];
  const fetchProducts = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/products/allProductsByCategory/${selectedCategory}`
    );
    const data = response.data;
    if (data.length !== products.length) {
      setProducts(data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [products]);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(products);
  }, [products]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        products.filter((product) => {
          return product.name.includes(searchInput);
        })
      );
    } else {
      setFilteredData(products);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData.length; i++) {
    const product = filteredData[i];
    data = [
      ...data,
      {
        image: (
          <img
            className="h-[50px] m-auto"
            src={
              product.product_images !== undefined &&
              product.product_images.length !== 0
                ? `${process.env.REACT_APP_API_PATH}/${product.product_images[0].image_url}`
                : "ddd"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/no-image.jpeg";
            }}
          />
        ),
        name: product.product_translations.[0].nameProduct,
        category: product.category_id,
        sku: product.sku_code,
        price: product.price,
        lastUpdated: product.updatedAt.slice(0,10),
        inStock: product.stock+" pcs",
        status: (
          <ApprovalSelector productId={product.id} status={product.status} />
        ),
        view: (
          <Link to={`/product-details?tab=1&src=cat`}>
            <div
              onClick={() => dispatch(setProduct({ id: product.id }))}
              className="bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
            onClick={() => {
              setItemToDelete({ id: product.id, index: i });
              setShowWarningModal(true);
            }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  return (
    <div>
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          rowHeight={rowHeight}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewProductToCategory
          products={products}
          setProducts={setProducts}
          setShowModal={setShowModal}
          categoryId={selectedCategory}
        />
      ) : null}
      {showWarningModal ? (
        <DeleteWarning
          url="/products/delete/"
          setShowModal={setShowWarningModal}
          itemToDelete={itemToDelete}
          items={products}
          setItems={setProducts}
        />
      ) : null}
      <div
        onClick={() => setShowModal(true)}
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[0px] ml-[20px] text-[13px]"
      >
        <div className="mr-2">Add new product </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </div>
  );
}

export default ProductsByCategory;
