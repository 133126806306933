/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSort } from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/selector.module.css";
import axios from "axios";

function ApprovalSelector({ status, productId, disabled }) {
  const [approved, setApproved] = useState(status);

  const changeState = async () => {
    if (!disabled) {
      await axios
        .get(
          `${process.env.REACT_APP_API_PATH}/products/update/state/${productId}`
        )
        .then((res) => {
          setApproved(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  return (
    <div
      onClick={changeState}
      className="w-[100%] cursor-pointer font-[400] flex items-center text-[13px] h-[35px] mr-2 rounded "
    >
      <div className={approved ? "text-[green] mr-2" : "text-[red] mr-2"}>
        {approved ? "Approved" : "Not Approved"}
      </div>{" "}
      <div>
        <FontAwesomeIcon icon={faSort} />
      </div>
    </div>
  );
}

export default ApprovalSelector;
