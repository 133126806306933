const style = {
  border: "none",
  color: "#033362",
  fontSize: "14px",
  fontWeight: "lighter",
};

const columns = [
  {
    name: "title",
    header: <h3 style={{ fontWeight: "700", fontFamily:'Poppins' }}>Title</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className:"text-[14px] font-[400] font-arabic text-[#0388CC]"
  },
  {
    name: "description",
    header: <h3 style={{ fontWeight: "700", fontFamily:'Poppins' }}>Description</h3>,
    defaultFlex: 10,
    headerProps: { style: style },
    className:"text-[14px] font-[400] font-arabic"
  },
  {
    name: "category",
    header: <h3 style={{ fontWeight: "700", fontFamily:'Poppins' }}>Category</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className:"text-[14px] text-[#0388CC] font-[400] font-arabic"
  },

  {
    name: "lastUpdated",
    header: <h3 style={{ fontWeight: "700" }}>Last Updated</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "view",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 1,
    headerProps: { style: style },
  },
  {
    name: "delete",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 1,
    headerProps: { style: style },
  },
];

export default columns;
