// import Image from "next/image";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Product({ item }) {
  const [itemData, setItemData] = useState({});
  const [itemCategory, setItemCategory] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_PATH + "/products/" + item.product_id)
      .then((res) => {
        setItemData(res.data);
        axios
          .get(
            process.env.REACT_APP_API_PATH +
              "/categories/" +
              res.data.category_id
          )
          .then((res2) => {
            setItemCategory(res2.data);
          });
      });
  }, []);

  const [count, setCount] = useState(0);
  return (
    <div className="flex flex-col ">
      <div className="   flex justify-center items-center  rounded-md w-[80%] mx-[10%] h-16 ">
        <div className="flex-[20%] mb-2 ml-4 justify-center  items-center flex ">
          <img
            src={`${process.env.REACT_APP_API_PATH}/${item.product_image}`}
            width={65}
            height={65}
          />
        </div>
        <div className="flex-[60%] flex-col flex py-2 px-5 ">
          <p className="text-sm opacity-75 ml-1 mt-1 tracking-wide">
            {itemData.product_translations.[1].nameProduct} -{" "}
            {itemCategory..category_translations.[1].name}
          </p>
        </div>
        <div className="flex-[60%] flex-col flex py-2 px-5 ">
          <p className="text-sm opacity-75 ml-1 mt-1 tracking-wide">
            {item.id}
          </p>
        </div>
        <div className="flex-[20%] font-bold text-[16px] opacity-75  justify-center items-center flex">
          {item.quantity} pcs
        </div>
      </div>
      <span className=" p-[1px] flex justify-center w-[70%] mb-2 items-center mx-[15%] bg-gray-300 "></span>
    </div>
  );
}
