import React, { useEffect, useState } from "react";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/cooksByCategoryColumns";
import NewCook from "../Modals/NewCook";
import {
  faCirclePlus,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { selectCooksCategory } from "../../redux/slices/itemSlice";
import axios from "axios";
import { Link } from "react-router-dom";
import DeleteWarning from "../Modals/DeleteWarning";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
import { selectUser } from "../../redux/slices/userSlice";
function CooksByCategory() {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);
  const dispatch = new useDispatch();
  const searchInput = useSelector(selectSearch);
  const selectedCooksCategory = useSelector(selectCooksCategory);
  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const [rowHeight, setRowHeight] = useState(60);
  const showCellBorders = false;
  const showZebraRows = false;
  const [showModal, setShowModal] = React.useState(false);

  const [cooks, setCooks] = useState([]);
  var data = [];
  const fetchProducts = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/cooks/cooksbycategory/${selectedCooksCategory.id}`
    );
    const data = response.data;
    if (data.length !== cooks.length) {
      setCooks(data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [cooks]);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(cooks);
  }, [cooks]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        cooks.filter((cook) => {
          return cook.cooks_translations.[0].name.includes(searchInput);
        })
      );
    } else {
      setFilteredData(cooks);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData.length; i++) {
    const cook = filteredData[i];
    data = [
      ...data,
      {
        image: (
          <img
            className="h-[50px] m-auto"
            src={
              process.env.REACT_APP_API_PATH +
              "/" +
              cook.image_background_url
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/no-image.jpeg";
            }}
          />
        ),
        name: cook.cooks_translations.[0].name,
        standards: cook.ingrendients,
        category: "Some Category Name",
        video: (
          <a href={cook.video_url} target="_blank">
            {cook.video_url}
          </a>
        ),
        lastUpdated: cook.updatedAt.slice(0,10),
        // view: (
        //   <Link to={`/cook-edit?id=${cook.id}`}>
        //     <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
        //       <FontAwesomeIcon icon={faEye} />
        //     </div>
        //   </Link>
        // ),
        view: (
          <Link
            to={!disabled ? `/cook-edit?id=${cook.id}` : "#"}
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
            onClick={() => {
              if (!disabled) {
                setItemToDelete({ id: cook.id, index: i });
                setShowWarningModal(true);
              }
            }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  return (
    <div>
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          rowHeight={rowHeight}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewCook
          setCooks={setCooks}
          cooks={cooks}
          selectedCooksCategory={selectedCooksCategory}
          setShowModal={setShowModal}
        />
      ) : null}
      {showWarningModal ? (
        <DeleteWarning
          url="/cooks/delete/"
          setShowModal={setShowWarningModal}
          itemToDelete={itemToDelete}
          items={cooks}
          setItems={setCooks}
        />
      ) : null}
      <div
        onClick={() => setShowModal(true)}
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[15px] ml-[25px] text-[13px]"
      >
        <div className="mr-2">Add new cook </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </div>
  );
}

export default CooksByCategory;
