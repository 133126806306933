import {
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import { selectSearch } from "../redux/slices/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../redux/slices/itemSlice";
function SearchBar({ setSearchInput, placeholder}) {
  const dispatch = new useDispatch();
  const searchInput = useSelector(selectSearch);
  

  return (
    <>
      <div className="mr-2 font-[500] text-[16px] text-[#00AEEF]">Search</div>
      <div className="w-full">
        <input value={searchInput} placeholder={placeholder} onChange={(e)=>{dispatch(setSearch(e.target.value))}} className="w-full font-arabic max-w-full bg-[#fff] border-[1px] border-[grey] rounded-full pl-4 pr-12 h-8 text-[14px] font-[400]" />
        <span className="cursor-pointer text-[14px] font-[400] ml-[-30px]">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
      </div>
    </>
  );
}

export default SearchBar;
