const style = {
  border: "none",
  color: "#033362",
  fontSize: "14px",
  fontWeight: "lighter",
};

const columns = [
  {
    name: "id",
    header: <h3 style={{ fontWeight: "700" }}>ID</h3>,
    defaultFlex: 0.6,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "email",
    header: <h3 style={{ fontWeight: "700" }}>Email</h3>,
    defaultFlex: 3,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "phone",
    header: <h3 style={{ fontWeight: "700" }}>Phone Number</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className:"text-[14px] w-fit px-[5px] font-[400] rounded text-[#0388CC]"
  },
  {
    name: "role",
    header: <h3 style={{ fontWeight: "700" }}>Role</h3>,
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "registred",
    header: <h3 style={{ fontWeight: "700" }}>Registred</h3>,
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "delete",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 1,
    headerProps: { style: style },
  },
];

export default columns;
