import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import axios from "axios";
import NewCookCategory from "../components/Modals/NewCookCategory";
import { Link } from "react-router-dom";
import {
  faUser,
  faCaretDown,
  faCirclePlus,
  faGear,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../global/columns/cooksCategoryColumns";
import { setCooksCategory } from "../redux/slices/itemSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
import DeleteWarning from "../components/Modals/DeleteWarning";
import Breadcrumbs from "../components/Breadcrumbs";
import SearchBar from "../components/SearchBar";
import UserIcon from "../components/UserIcon";
import { selectSearch } from "../redux/slices/itemSlice";
import { setSearch } from "../redux/slices/itemSlice";
import Footer from "../components/Footer";
function CooksCategories() {
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);

  const dispatch = useDispatch();
  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };

  const showCellBorders = false;
  const showZebraRows = false;
  const [rowHeight, setRowHeight] = useState(60);
  const [showModal, setShowModal] = React.useState(false);

  const [cooksCategories, setCooksCategories] = useState([]);
  var data = [];

  const fetchCooksCategories = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/cooks-categories/findall`
    );
    if (response.data.length !== cooksCategories.length) {
      setCooksCategories(response.data);
    }
    
  };

  useEffect(() => {
    fetchCooksCategories();
  }, [cooksCategories]);

  
  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(cooksCategories);
  }, [cooksCategories]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        cooksCategories.filter((cooksCategory) => {
          return cooksCategory.cooks_.category_translations.[0].title.includes(
            searchInput
          );
        })
      );
    } else {
      setFilteredData(cooksCategories);
    }
  }, [searchInput]);

  useEffect(() => {
    fetchCooksOfCategories();
  }, [filteredData]);

  const [cooksByCategory, setCooksByCategory] = useState([]);
  const fetchCooksOfCategories = async () => {
    var cooks = [];
    for (let i = 0; i < filteredData.length; i++) {
      var response2 = await axios.get(
        `${process.env.REACT_APP_API_PATH}/cooks/cooksbycategory/${filteredData.[i].id}`
      );
      cooks = [...cooks, response2.data];
    }
    setCooksByCategory(cooks);
  };

  for (let i = 0; i < filteredData.length; i++) {
    const cooksCategory = filteredData.[i];
    data = [
      ...data,
      {
        image: (
          <img
            className="h-[50px] m-auto"
            src={`${process.env.REACT_APP_API_PATH}/${cooksCategory.image_url}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/no-image.jpeg";
            }}
          />
        ),
        id: cooksCategory.id,
        name: cooksCategory.cooks_.category_translations.[0].title,
        cooksByCategories: cooksByCategory.[i].map(
          (cook) => cook.cooks_translations.[0].name + " - "
        ),
        cooks: cooksByCategory.[i].length,
        lastUpdated: "01/01/01",
        view: (
          <Link to={!disabled ? "/cooks-category-details?tab=1" : "#"}>
            <div
              onClick={() =>
                dispatch(setCooksCategory({ id: cooksCategory.id }))
              }
              className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
          onClick={() => {
            if (!disabled) {
              setItemToDelete({ id: cooksCategory.id, index: i });
              setShowWarningModal(true);
            }
          }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>
        <div className="w-full flex flex-col ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <Breadcrumbs
              breads={[
                {
                  title: "Cooks Categories",
                  link: "/cooks-categories",
                },
              ]}
            />
            <div className="flex items-center h-8 w-[30%]">
              <SearchBar placeholder="Search by cook category name" />
              <UserIcon />
            </div>
          </div>
          <div className="bg-[white] flex flex-col justify-between grow mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
            <div className=" p-3 w-full">
              <ReactDataGrid
                idProperty="id"
                columns={columns}
                showCellBorders={showCellBorders}
                showZebraRows={showZebraRows}
                style={gridStyle}
                rowHeight={rowHeight}
                dataSource={data}
              />
            </div>
            {showModal ? (
              <NewCookCategory
                cooksCategories={cooksCategories}
                setCooksCategories={setCooksCategories}
                setShowModal={setShowModal}
              />
            ) : null}
            {showWarningModal ? (
              <DeleteWarning
                url="/cooks-categories/delete/"
                setShowModal={setShowWarningModal}
                itemToDelete={itemToDelete}
                items={cooksCategories}
                setItems={setCooksCategories}
              />
            ) : null}
            <div
              onClick={() => !disabled && setShowModal(true)}
              className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[15px] ml-[25px] text-[13px]"
            >
              <div className="mr-2">Add new cook category </div>
              <div className="text-[20px]">
                <FontAwesomeIcon icon={faCirclePlus} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default CooksCategories;
