import React, { useEffect } from "react";
import SideBar from "../components/SideBar";
import UpperTotal from "../components/UpperTotal";
import SideTotal from "../components/SideTotal";
import LineChart from "../components/LineChart";
import DoughnutChart from "../components/DoughnutChart";
import Notification from "../components/Notification";
import {
  faCircle,
  faArrowDown,
  faArrowUp,
  faUser,
  faCaretDown,
  faMagnifyingGlass,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../global/columns/orderColumns";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/profilepopup.module.css";
import styles2 from "../styles/notificationspopup.module.css";
import styles3 from "../styles/selector.module.css";
import axios from "axios";
import StatusSelector from "../components/StatusSelector";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
import UserIcon from "../components/UserIcon";
import Footer from "../components/Footer";
const Home = () => {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);
  const navigate = useNavigate();
  const dispatch = new useDispatch();
  const gridStyle = {
    minHeight: 275,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;

  const [userData, setUserData] = useState(null);
  const [ordersMonthly, setOrdersMonthly] = useState([]);
  const [ordersWeekly, setOrdersWeekly] = useState([]);
  const [salesWeekly, setSalesWeekly] = useState([]);
  const [salesMonthly, setSalesMonthly] = useState([]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_PATH + "/statistics/graph/monthly/orders")
      .then((response) => {
        setOrdersMonthly(response.data);
      });
    axios
      .get(process.env.REACT_APP_API_PATH + "/statistics/graph/weekly/orders")
      .then((response) => {
        setOrdersWeekly(response.data);
      });
    axios
      .get(process.env.REACT_APP_API_PATH + "/statistics/graph/monthly")
      .then((response) => {
        setSalesMonthly(response.data);
      });
    axios
      .get(process.env.REACT_APP_API_PATH + "/statistics/graph/weekly")
      .then((response) => {
        setSalesWeekly(response.data);
      });
  }, []);

  const [graphSelected, setGraphSelected] = useState({
    timing: "monthly",
    graphType: "orders",
  });
  useEffect(() => {
    let selectedData = null;
    if (
      graphSelected.timing === "monthly" &&
      graphSelected.graphType === "orders"
    ) {
      selectedData = ordersMonthly;
    } else if (
      graphSelected.timing === "weekly" &&
      graphSelected.graphType === "orders"
    ) {
      selectedData = ordersWeekly;
    } else if (
      graphSelected.timing === "weekly" &&
      graphSelected.graphType === "sales"
    ) {
      selectedData = salesWeekly;
    } else if (
      graphSelected.timing === "monthly" &&
      graphSelected.graphType === "sales"
    ) {
      selectedData = salesMonthly;
    }
    setUserData({
      labels: selectedData.map((data) => data.month),
      datasets: [
        {
          label: false,
          data: selectedData.map((data) => data.userGain),
          borderColor: "#00AEEF",
          borderWidth: 2,
          tension: 0.5,
          pointStyle: "line",
          pointRotation: 90,
          cubicInterpolationMode: "monotone",
          options: {
            legend: { display: false },
          },
          fill: {
            target: "origin",
            above: "rgba(0, 174, 239,0.15)",
          },
        },
      ],
    });
  }, [ordersMonthly, graphSelected, salesMonthly]);

  const [adminData, setAdminData] = useState(null);
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_PATH +
          "/users/getoneuser/" +
          currentUser.user.[0][1]
      )
      .then((response) => {
        setAdminData(response.data);
      })
      .catch((error) => {
        if (error.response.data.message === "user not exist") {
          signOut();
        }
      });
  }, []);

  const doughnutData2 = {
    labels: [" f", "fs "],
    datasets: [
      {
        label: "Weekly Sales",
        data: [1520, 76],
        backgroundColor: ["#0388CC", "#fff"],
        borderColor: ["#0388CC", "#fff"],
        borderWidth: 1,
      },
      {
        label: "Weekly Sales",
        data: [0, 0],
        backgroundColor: ["#fff", "#fff"],
        borderColor: ["#fff", "#fff"],
        borderWidth: 1,
      },
      {
        label: "Weekly Sales",
        data: [1520, 76],
        backgroundColor: ["#cecece", "#0388CC"],
        borderColor: ["#cecece", "#0388CC"],
        borderWidth: 1,
      },
    ],
  };

  const [doughnutData, setDoughnutData] = useState(null);

  const fetchDoughnutData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/statistics/graph/mkwdalih`
    );
    const data = response.data;
    setDoughnutData(data);
  };

  const [notifications, setNotifications] = useState([]);
  const fetchNotifications = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/notifications/findall`
    );
    const data = response.data;
    setNotifications(data);
  };


  const [statistics, setStatistics] = useState({});
  const fetchStatistics = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/statistics/`
    );
    const data = response.data;
    setStatistics(data);
  };

  const [orders, setOrders] = useState([]);
  var data = [];
  const fetchOrders = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/orders/findAll`
    );
    const data = response.data;
    if (data.length !== orders.length) {
      setOrders(data);
    }
  };

  useEffect(() => {
    fetchOrders();
    fetchStatistics();
    fetchNotifications();
    fetchDoughnutData();
  }, []);

  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    data = [
      ...data,
      {
        id: (
          <div className="text-[14px]  font-[300]  text-[#0388CC]">
            {order.id}{" "}
          </div>
        ),
        phone: (
          <div className="text-[14px] font-[600] underline text-[#0F3F62]">
            {order.receiver_phone}
          </div>
        ),
        email: (
          <div className="text-[14px] font-[300] underline text-[#0388CC]">
            {order.receiver_name}
          </div>
        ),
        products: <div className="font-[400]">{order.order_items.length}</div>,
        adress: <div className="font-[400]">{order.shipping_add} </div>,
        time: (
          <div className="font-[400]">{order.CreatedAt.slice(11, 19)} </div>
        ),
        date: <div className="font-[400]">{order.CreatedAt.slice(0, 10)}</div>,
        total: (
          <div className="text-[14px] font-[600] text-[#000]">
            {order.total}
          </div>
        ),
        status: (
          <div className="z-50 overflow-visible l-[35px] px-1 font-[400] text-[white] py-[2px] min-w-[100px] rounded-md">
            <StatusSelector
              disabled={!["Sales", "Admin"].includes(adminRole)}
              key={order.order_status}
              orderId={order.id}
              status={order.order_status}
            />
          </div>
        ),
        view: (
          <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
            <Link
              to={
                ["Sales", "Admin"].includes(adminRole)
                  ? `/order-details?id=${order.id}`
                  : "#"
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </div>
        ),
      },
    ];
  }

  const signOut = () => {
    dispatch(logout());
    navigate("/login");
  };


  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>

        <div className="w-full  flex flex-col ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <div className="px-2">Dashboard</div>
            <div className={`px-2 ${styles2.sideBtn}`}>
              <span className="cursor-pointer">
                <FontAwesomeIcon icon={faBell} />
              </span>
              <div className={`${styles2.popup} ${styles2.arrowTop}`}>
                <div
                  className={`text-[12px]  text-start font-bold ${styles2.popupWrapper}`}
                >
                  <div className="mt-[10px] font-[400] text-[#0388CC] text-[13px]">
                    Notifications
                  </div>
                  <div className="max-h-[380px] scrollbar-thin scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    <div>
                      {notifications.map((notification) => (
                        <Notification
                          key={notification.id}
                          text={notification.message}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[65%]">
              <input className="w-full max-w-full bg-[#fff] rounded-full pl-4 pr-12 h-8 text-[14px] font-[400]" />
              <span className="cursor-pointer text-[14px] font-[400] ml-[-30px]">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </span>
            </div>
            <div className="px-2 min-w-fit">
              {dateState.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }) + " - "}
              {dateState.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </div>
            <UserIcon/>
          </div>
          <div className="grow">
            <div className="w-full divide-x justify-evenly bg-[#fff] flex h-[14%] mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
              <UpperTotal
                color="text-[#033362]"
                icon="total-revenue"
                title="Total Revenue"
                arTitle="إجمالي المبيعات"
                total={statistics.revenue}
                size="w-[40px]"
              />
              <UpperTotal
                color="text-[#00AEEF]"
                icon="total-orders"
                title="Total Orders"
                arTitle="إجمالي الطلبات"
                total={statistics.all_Orders}
                size="w-[36px]"
              />
              <UpperTotal
                color="text-[#39B54A]"
                icon="total-deliveries"
                title="Total Deliveries"
                arTitle="الطلبات المكتملة"
                total={statistics.paid_Orders}
                size="w-[44px]"
              />
              <UpperTotal
                color="text-[#00AEEF]"
                icon="active-orders"
                title="Active Orders"
                arTitle="طلبات جارية"
                total={statistics.active_Orders}
                size="w-[22px]"
              />
              <UpperTotal
                color="text-[#F7941D]"
                icon="pending-orders"
                title="Pending Orders"
                arTitle="طلبات معلقة"
                total={statistics.pending_Orders}
                size="w-[24px]"
              />
              <UpperTotal
                color="text-[#FF4A4A]"
                icon="cancelled-orders"
                title="Cencelled Orders "
                arTitle="طلبات ملغية"
                total={statistics.canceled_Orders}
                size="w-[24px]"
              />
            </div>

            <div className="w-full mt-4 h-[81%] flex justify-between">
              <div className="w-[79.5%] flex flex-col justify-between">
                <div className="flex justify-between h-[40.5%]">
                  <div className="bg-[#fff] rounded-xl w-[69.5%] shadow-[0px_0px_16px_rgb(210,215,211)]">
                    <div className="flex justify-between items-center w-[98%] mt-[4px] h-[45px] text-[13px]">
                      <div className="flex items-center">
                        <div className="ml-[35px] text-[green] text-[7px]">
                          <FontAwesomeIcon icon={faCircle} />
                        </div>
                        <div className="ml-[10px]"> Recent orders</div>

                        <div className="ml-[35px] px-1 font-[400] text-[white] py-[2px] min-w-[80px] rounded-md bg-[#0388CC]">
                          <div className={`${styles3.selectBox} bg-[#0388CC]`}>
                            <select
                              value={graphSelected.graphType}
                              onChange={(e) => {
                                setGraphSelected({
                                  ...graphSelected,
                                  graphType: e.target.value,
                                });
                              }}
                              className={`pl-2 bg-[#0388CC] outline-none cursor-pointer font-sans rounded py-[3px]  border-none text-[white] w-[100%] `}
                            >
                              <option
                                value="orders"
                                className={` outline-[0px] hover:bg-[white] bg-[#0388CC] py-[4px] rounded`}
                              >
                                Orders
                              </option>
                              <option
                                value="sales"
                                className={` outline-[0px] hover:bg-[white] bg-[#0388CC] py-[4px] rounded`}
                              >
                                Sales
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="ml-[35px] font-arabic font-[400]">
                          تحليل المبيعات
                        </div>
                      </div>
                      <div className="ml-[35px] px-1 font-[400] text-[white] py-[2px] min-w-[90px] rounded-md ">
                        <div
                          className={`${styles3.selectBox2} bg-[white] border-[1px] border-[#0388CC] `}
                        >
                          <select
                            value={graphSelected.timing}
                            onChange={(e) => {
                              setGraphSelected({
                                ...graphSelected,
                                timing: e.target.value,
                              });
                            }}
                            className={`pl-2 bg-[#0388CC] outline-none cursor-pointer font-sans rounded py-[3px]  border-none text-[#0388CC] w-[100%] `}
                          >
                            <option
                              value="monthly"
                              className={`outline-[0px] hover:bg-[white] bg-[white] py-[4px] rounded`}
                            >
                              Monthly
                            </option>
                            <option
                              value="weekly"
                              className={` outline-[0px] text-[#0388CC] hover:bg-[white] bg-[white] py-[4px] rounded`}
                            >
                              Weekly
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="h-[80%] m-auto w-[95%]">
                      {userData && <LineChart chartData={userData} />}
                    </div>
                  </div>
                  <div className="bg-[#fff] rounded-xl w-[29.5%] shadow-[0px_0px_16px_rgb(210,215,211)]">
                    <div className="h-[60%] mt-[30px]">
                      {doughnutData && (
                        <DoughnutChart chartData={doughnutData} />
                      )}
                    </div>
                    <div className="flex justify-between px-[12%] mt-[14px]">
                      <div className="border-l-solid border-l-2 border-l-[#00AEEF] px-2">
                        <div className="text-[14px]">{statistics.revenue}</div>
                        <span className="text-[10px] text-[#7C8DB5] font-thin">
                          Income{" "}
                          <span className="ml-[12px]">
                            <FontAwesomeIcon icon={faArrowUp} />
                          </span>
                        </span>
                      </div>
                      <div className="border-l-solid border-l-2 border-l-[#00AEEF] px-2">
                        <div className="text-[14px]">{statistics.taxes}</div>
                        <span className="text-[10px] text-[#7C8DB5] font-thin">
                          Taxes{" "}
                          <span className="ml-[12px]">
                            <FontAwesomeIcon icon={faArrowDown} />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-[#fff] rounded-xl h-[56.5%] shadow-[0px_0px_16px_rgb(210,215,211)]">
                  <div className="flex items-center w-[50%] mt-[4px] h-[45px] text-[13px]">
                    <div className="ml-[35px]  text-[#0388CC] text-[7px]">
                      <FontAwesomeIcon icon={faCircle} />
                    </div>
                    <div className="ml-[10px]"> Recent orders</div>
                    <div className="ml-[35px] px-1 font-[400] text-[white] py-[2px] min-w-[100px] rounded-md">
                      <div className={`${styles3.selectBox} bg-[#0388CC]`}>
                        <select
                          className={`pl-2 bg-[#0388CC] outline-none cursor-pointer font-sans rounded py-[3px]  border-none text-[white] w-[100%] `}
                        >
                          <option
                            value="s"
                            className={` outline-[0px] hover:bg-[white] bg-[#0388CC] py-[4px] rounded`}
                          >
                            All Orders
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="ml-[35px] font-arabic font-[400]">
                      إدارة الطلبات
                    </div>
                  </div>
                  <div className=" px-3 w-full">
                    <ReactDataGrid
                      idProperty="id"
                      columns={columns}
                      showCellBorders={showCellBorders}
                      showZebraRows={showZebraRows}
                      style={gridStyle}
                      dataSource={data}
                    />
                  </div>
                </div>
              </div>
              <div className="w-[19.5%] flex flex-col justify-between ">
                <SideTotal
                  color="text-[#39B54A]"
                  icon="tax-amount"
                  title="Tax Amount"
                  total={statistics.taxes}
                />
                <Link to="/products">
                  <SideTotal
                    color="text-[#39B54A]"
                    icon="in-stock-products"
                    title="In Stock Products"
                    total={statistics.in_stock_products}
                  />
                </Link>
                <Link to="/products">
                  <SideTotal
                    color="text-[#FF4A4A]"
                    icon="out-of-stock-products"
                    title="Out Of Stock Products"
                    total={statistics.outOfStockProduct}
                  />
                </Link>
                <Link to="/products">
                  <SideTotal
                    color="text-[#00AEEF]"
                    icon="active-products"
                    title="Active Products"
                    total={statistics.active_products}
                  />
                </Link>
                <Link to="/categories">
                  <SideTotal
                    color="text-[#F7941D]"
                    icon="categories"
                    title="Categories"
                    total={statistics.categories}
                  />
                </Link>
                <Link to="/users?tab=2">
                  <SideTotal
                    color="text-[#007530]"
                    icon="customers"
                    title="Customers"
                    total={statistics.customers}
                  />
                </Link>
                <Link to="/users?tab=1">
                  <SideTotal
                    color="text-[#0388CC]"
                    icon="admin-users"
                    title="Admin Users"
                    total={statistics.admins}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
