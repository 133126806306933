import "./App.css";
import { Navigate, BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Orders from "./pages/Orders";
import Products from "./pages/Products";
import Categories from "./pages/Categories";
import Pages from "./pages/Pages";
import Users from "./pages/Users";
import News from "./pages/News";
import Messages from "./pages/Messages";
import Careers from "./pages/Careers";
import Offers from "./pages/Offers";
import Settings from "./pages/Settings";
import CategoryDetails from "./pages/CategoryDetails";
import Features from "./pages/Features";
import CooksCategories from "./pages/CooksCategories";
import CooksCategoryDetails from "./pages/CooksCategoryDetails";
import Cooks from "./pages/Cooks";
import CookEdit from "./pages/CookEdit";
import ProductDetails from "./pages/ProductDetails";
import CustomerDetails from "./pages/CustomerDetails";
import ActivityEdit from "./pages/ActivityEdit";
import NewsEdit from "./pages/NewsEdit";
import JobApplicationInfo from "./pages/JobApplicationInfo";
import JobPositionEdit from "./pages/JobPositionEdit";
import EmailManagement from "./pages/EmailManagement";
import OrderDetails from "./pages/OrderDetails";
import CouponDetails from "./pages/CouponDetails";
import OfferDetails from "./pages/OfferDetails";
import Login from "./pages/Login";
import { useSelector } from "react-redux";
import { selectUser } from "./redux/slices/userSlice";

function App() {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const routing = [
    {
      allowedRoles:["Admin", "Sales"],
      path: "/orders",
      component: <Orders />,
    },
    {
      allowedRoles:["Admin", "DataEntry"],
      path: "/products",
      component: <Products />,
    },
    {
      allowedRoles:["Admin", "DataEntry"],
      path: "/categories",
      component: <Categories />,
    },
    {
      allowedRoles:["Admin", "DataEntry"],
      path: "/pages",
      component: <Pages />,
    },
    {
      allowedRoles:["Admin"],
      path: "/users",
      component: <Users />,
    },
    {
      allowedRoles:["Admin", "DataEntry"],
      path: "/news",
      component: <News />,
    },
    {
      allowedRoles:["Admin", "Messaging"],
      path: "/messages",
      component: <Messages />,
    },
    {
      allowedRoles:["Admin", "HR"],
      path: "/careers",
      component: <Careers />,
    },
    {
      allowedRoles:["Admin", "Sales"],
      path: "/offers",
      component: <Offers />,
    },
    {
      allowedRoles:["Admin"],
      path: "/settings",
      component: <Settings />,
    },
    {
      allowedRoles: ["DataEntry", "Admin"],
      path: "/category-details",
      component: <CategoryDetails />,
    },
    {
      allowedRoles:["Admin", "DataEntry"],
      path: "/cooks-categories",
      component: <CooksCategories />,
    },
    {
      allowedRoles:["Admin", "DataEntry"],
      path: "/cook-edit",
      component: <CookEdit />,
    },
    {
      allowedRoles: ["DataEntry", "Admin"],
      path: "/cooks-category-details",
      component: <CooksCategoryDetails />,
    },
    {
      allowedRoles: ["DataEntry", "Admin"],
      path: "/product-details",
      component: <ProductDetails />,
    },
    {
      allowedRoles:["Admin"],
      path: "/customer",
      component: <CustomerDetails />,
    },
    {
      allowedRoles: ["DataEntry", "Admin"],
      path: "/activity-edit",
      component: <ActivityEdit />,
    },
    {
      allowedRoles: ["DataEntry", "Admin"],
      path: "/news-edit",
      component: <NewsEdit />,
    },
    {
      allowedRoles: ["HR", "Admin"],
      path: "/job-application-info",
      component: <JobApplicationInfo />,
    },
    {
      allowedRoles: ["HR", "Admin"],
      path: "/job-position-edit",
      component: <JobPositionEdit />,
    },
    {
      allowedRoles: ["Messaging", "Admin"],
      path: "/emails",
      component: <EmailManagement />,
    },
    {
      allowedRoles: ["Sales", "Admin"],
      path: "/order-details",
      component: <OrderDetails />,
    },
    {
      allowedRoles: ["Sales", "Admin"],
      path: "/coupon-details",
      component: <CouponDetails />,
    },
    {
      allowedRoles: ["Sales", "Admin"],
      path: "/offer-details",
      component: <OfferDetails />,
    },
    {
      path: "/",
      component: <Home />,
    },
  ];
  return (
    <BrowserRouter>
      <div>
        <Routes>
          {routing.map((route) => (
            <Route
              path={route.path}
              element={
                (currentUser.isLoggedIn &&
                  route.allowedRoles.includes(adminRole)) ||
                (!route.allowedRoles && currentUser.isLoggedIn) ? (
                  route.component
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
          ))}
          <Route
            path="/login"
            element={
              !currentUser.isLoggedIn ? <Login /> : <Navigate replace to="/" />
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
