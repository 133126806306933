import React, { useState } from "react";
import styles from "../../styles/settingsTabs.module.css";
import FileUpload from "../FileUploader/FileUpload";
import FileItem from "../FileUploader/FileItem";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
function Video({ videoUrl, setVideoUrl }) {
  return (
    <div key={33} className={`${styles.tab} flex items-center`}>
      <input
        value="33"
        type="radio"
        name="settings-tabs"
        id="33"
        className={`${styles.tabSwicth}`}
      />
      <label htmlFor="33" id="33" className={`${styles.tabLabel}`}>
        <span className={`${styles.tabSpan} font-[400] text-[14px]`}>
          Video
        </span>
      </label>
      <div className={`${styles.tabContent}`}>
        <span>
          <div className="text-[#000] text-[14px] font-[400]">
            <span className="mr-4">Video URL:</span>
            <input
              className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              placeholder="Enter Video URL"
              onChange={(e) => {
                setVideoUrl(e.target.value);
              }}
              value={videoUrl}
              type="url"
            />
          </div>
        </span>
      </div>
    </div>
  );
}

export default Video;
