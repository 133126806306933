import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
function ContactForm({ contact, index, deleteContact, disabled }) {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const disabled2 = !["DataEntry", "Admin"].includes(adminRole);
  return (
    <div className="flex w-full mb-[10px]  py-[20px]">
      <div>
        <div className="text-[#000] font-[700] text-[14px]">{index + 1}.</div>
      </div>
      <div className="ml-[6%] w-[85%]">
        <div>
          <p className="text-[#0388CC] font-[700] text-[14px]">
            Location {index + 1}
          </p>
          <div className="mt-[10px] flex justify-between w-full">
            <div className="w-[250px]">
              <label className="text-[14px] font-[400]" htmlFor="branch-name">
                Name Of Branch <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                disabled={disabled}
                className="w-full h-[30px] font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter branch name"
                value={contact.name}
                type="text"
                id="branch-name"
              />
            </div>
            <div className="w-[500px]">
              <label
                className="text-[14px] font-[400]"
                htmlFor="branch-ndetails"
              >
                Branch Details <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                disabled={disabled}
                className="w-full h-[30px] font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter branch details"
                value={contact.description}
                type="text"
                id="branch-details"
              />
            </div>
            <div className="w-[250px]">
              <label className="text-[14px] font-[400]" htmlFor="location">
                Select your Location on Map{" "}
                <span className="text-[red] font-[700]">*</span>
              </label>
              <div className="flex">
                <input
                  disabled={disabled}
                  className="w-full h-[30px] font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                  placeholder="Select branch location"
                  value={contact.Location}
                  type="text"
                  id="location"
                />
                <div className="h-[30px] ml-[3px] rounded w-[35px] leading- text-[14px] text-center text-[#fff] bg-[#0388CC] flex items-center justify-center">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
              </div>
            </div>
            {/* <div className="w-[200px]">
              <label className="text-[14px] font-[400]" htmlFor="delivery-time">
                Additional Fees{" "}
                <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                disabled={disabled}
                className="w-full h-[30px]  font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter delivery time"
                value={contact.additional_fees}
                name="additional_fees"
                type="text"
                id="branch-name"
              />
            </div> */}
          </div>
        </div>
        <div className="mt-[18px]">
          <p className="text-[#000] font-[700] text-[13px]">Details</p>
          <div className="flex justify-between w-full">
            <div className="w-[200px]">
              <label className="text-[14px] font-[400]" htmlFor="branch-name">
                Openings Times <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                disabled={disabled}
                className="w-full h-[30px] font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter opening times"
                value={contact.openings_time}
                type="text"
                id="branch-name"
              />
            </div>
            <div className="w-[200px]">
              <label className="text-[14px] font-[400]" htmlFor="branch-name">
                Telephone <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                disabled={disabled}
                className="w-full h-[30px] font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter phone number"
                value={contact.phone_number}
                type="text"
                id="branch-name"
              />
            </div>
            <div className="w-[200px]">
              <label className="text-[14px] font-[400]" htmlFor="branch-name">
                Fax <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                disabled={disabled}
                className="w-full h-[30px] font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter fax"
                value={contact.fax}
                type="text"
                id="branch-name"
              />
            </div>
            <div className="w-[200px]">
              <label className="text-[14px] font-[400]" htmlFor="branch-name">
                For the delivery timing{" "}
                <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                disabled={disabled}
                className="w-full h-[30px] font-[400] text-[15px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter delivery time"
                value={contact.delivery_time}
                type="text"
                id="branch-name"
              />
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            !disabled2 && deleteContact(index);
          }}
          className="mt-5 text-[red] text-[14px] font-[500] border-[1px] border-[red] rounded-md float-right py-[3px] px-2 cursor-pointer"
        >
          Delete
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
