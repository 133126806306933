const style = {
  border: "none",
  color: "#0388CC",
  fontSize: "13px",
  fontWeight: "lighter",
};

const columns = [
  {
    name: "image",
    header: <h3 style={{ fontWeight: "400" }}></h3>,
    defaultFlex: 1,
    headerProps: { style: style },
  },
  {
    name: "id",
    header: <h3 style={{ fontWeight: "400" }}>ID</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "name",
    header: <h3 style={{ fontWeight: "400", fontFamily:'Poppins' }}>Name</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className:"font-[400] font-arabic"
  },
  {
    name: "productsByCat",
    header: <h3 style={{ fontWeight: "400" }}>Products by category</h3>,
    defaultFlex: 3,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "products",
    header: <h3 style={{ fontWeight: "400" }}>Products</h3>,
    defaultFlex: 1.5,
    headerProps: { style: style },
    className:"text-[14px] w-fit px-[5px] font-[400] rounded text-[#0388CC]"
  },
  {
    name: "lastUpdate",
    header: <h3 style={{ fontWeight: "400" }}>Last update</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "view",
    header: <h3 style={{ fontWeight: "400" }}></h3>,
    defaultFlex: 1,
    headerProps: { style: style },
  },
  {
    name: "delete",
    header: <h3 style={{ fontWeight: "400" }}></h3>,
    defaultFlex: 1,
    headerProps: { style: style },
  },
];

export default columns;
