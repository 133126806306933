const style = {
  border: "none",
  color: "#0388CC",
  fontSize: "13px",
  fontWeight: "lighter",
};

const renderStock = ({ value }) => {
  return (
    <div
      style={{ fontWeight: "400", color: value === "0 pcs" ? "red" : "#000" }}
    >
      {value}
    </div>
  );
};

const columns = [
  {
    name: "image",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 0.7,
    headerProps: { style: style },
  },
  {
    name: "name",
    header: <h3 style={{ fontWeight: "700" }}>Name</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className: "text-[14px] font-[400] text-[#0388CC]",
  },
  {
    name: "sku",
    header: <h3 style={{ fontWeight: "700" }}>SKU</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className: "font-[400]",
  },
  {
    name: "price",
    header: <h3 style={{ fontWeight: "700" }}>Price</h3>,
    defaultFlex: 0.5,
    headerProps: { style: style },
    className:"text-[14px] font-[700] text-[#000]",
    type:"number"
  },
  {
    name: "lastUpdated",
    header: <h3 style={{ fontWeight: "700" }}>Last Update</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className: "font-[400]",
  },
  {
    name: "inStock",
    header: <h3 style={{ fontWeight: "700" }}>In Stock</h3>,
    defaultFlex: 0.5,
    headerProps: { style: style },
    render: renderStock,
  },
  {
    name: "status",
    header: <h3 style={{ fontWeight: "700" }}>Status</h3>,
    defaultFlex: 0.5,
    headerProps: { style: style },
  },
  {
    name: "view",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 0.5,
    headerProps: { style: style },
  },
  {
    name: "delete",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 0.5,
    headerProps: { style: style },
  },
];

export default columns;
