import React from "react";
import { useState, useCallback, useEffect } from "react";
import SideBar from "../components/SideBar";
import NewCategory from "../components/Modals/NewCategory";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  faUser,
  faCaretDown,
  faCirclePlus,
  faEye,
  faTrash,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../global/columns/categoryColumns";
import { useDispatch } from "react-redux";
import { setCategory } from "../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
import DeleteWarning from "../components/Modals/DeleteWarning";
import Breadcrumbs from "../components/Breadcrumbs";
import styles from "../styles/profilepopup.module.css";
import { logout } from "../redux/slices/userSlice";
import SearchBar from "../components/SearchBar";
import UserIcon from "../components/UserIcon";
import { selectSearch } from "../redux/slices/itemSlice";
import { setSearch } from "../redux/slices/itemSlice";
import Footer from "../components/Footer";
function Categories() {
  const navigate = useNavigate();
  const searchInput = useSelector(selectSearch);
  const dispatch = new useDispatch();
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);
  const [categories, setCategories] = useState([]);
  var data = [];
  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  const [rowHeight, setRowHeight] = useState(60);
  const [expandedNodes, setExpandedNodes] = useState({});
  const onExpandedNodesChange = useCallback(({ expandedNodes }) => {
    setExpandedNodes(expandedNodes);
  }, []);
  const [showModal, setShowModal] = React.useState(false);

  const fetchCategories = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/categories/findall`
    );
    setCategories(response.data);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  

  const [adminData, setAdminData] = useState(null);
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_PATH +
          "/users/getoneuser/" +
          currentUser.user.[0][1]
      )
      .then((response) => {
        setAdminData(response.data);
      });
  }, []);

  const signOut = () => {
    dispatch(logout());
    navigate("/login");
  };

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(categories);
  }, [categories]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        categories.filter((category) => {
          return category..category_translations[1].name.includes(searchInput);
        })
      );
    } else {
      setFilteredData(categories);
    }
  }, [searchInput]);
  
  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  useEffect(() => {
    fetchProductOfCategories();
  }, [filteredData]);

  const [productsByCategory, setProductsByCategory] = useState([]);
  const fetchProductOfCategories = async () => {
    var products = [];
    for (let i = 0; i < filteredData.length; i++) {
      var response2 = await axios.get(
        `${process.env.REACT_APP_API_PATH}/products/allProductsByCategory/${filteredData.[i].id}`
      );
      products = [...products, response2.data];
    }
    setProductsByCategory(products);
  };

  for (let i = 0; i < filteredData.length; i++) {
      data = [
        ...data,
        {
          image: (
            <img
              className="h-[50px] m-auto"
              src={`${process.env.REACT_APP_API_PATH}/${filteredData[i].image_url}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/no-image.jpeg";
              }}
            />
          ),
          id: filteredData[i].id,
          name: filteredData[i]..category_translations[1].name,
          productsByCat: productsByCategory[i].map(
            (product) => product.product_translations[0].nameProduct + " - "
          ),
          products: productsByCategory[i].length,
          lastUpdate: filteredData[i].updatedAt.slice(0,10),
          view: (
            <Link
              to={
                ["DataEntry", "Admin"].includes(adminRole)
                  ? `/category-details?tab=1`
                  : "#"
              }
            >
              <div
                onClick={() => dispatch(setCategory(filteredData[i].id))}
                className="bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
              >
                <FontAwesomeIcon icon={faEye} />
              </div>
            </Link>
          ),
          delete: (
            <div
              onClick={() => {
                if (!disabled) {
                  setItemToDelete({ id: filteredData[i].id, index: i });
                  setShowWarningModal(true);
                }
              }}
              className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
          ),
          folder: true,
          nodes: [],
        },
      ];
    
  }

  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>
        <div className="w-full flex flex-col ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <Breadcrumbs
              breads={[
                {
                  title: "Categories",
                  link: "/categories",
                },
              ]}
            />
            <div className="flex items-center h-8 w-[30%]">
              <SearchBar placeholder="Search by category name" />
              <UserIcon />
            </div>
          </div>
          <div className="bg-[white] flex flex-col justify-between grow mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
            <div className=" p-3 w-full">
              <ReactDataGrid
                treeColumn="image"
                columns={columns}
                expandedNodes={expandedNodes}
                onExpandedNodesChange={onExpandedNodesChange}
                showCellBorders={showCellBorders}
                showZebraRows={showZebraRows}
                rowHeight={rowHeight}
                style={gridStyle}
                dataSource={data}
              />
            </div>
            {showModal ? (
              <NewCategory
                categories={categories}
                setCategories={setCategories}
                setShowModal={setShowModal}
              />
            ) : null}
            {showWarningModal ? (
              <DeleteWarning
                url="/categories/delete/"
                setShowModal={setShowWarningModal}
                itemToDelete={itemToDelete}
                items={categories}
                setItems={setCategories}
              />
            ) : null}
            <div
              onClick={() =>
                ["DataEntry", "Admin"].includes(adminRole) && setShowModal(true)
              }
              className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[15px] ml-[25px] text-[13px]"
            >
              <div className="mr-2">Add new category </div>
              <div className="text-[20px]">
                <FontAwesomeIcon icon={faCirclePlus} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Categories;
