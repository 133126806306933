import React, { useState } from "react";
import RichTextEditor from "./RichTextEditor";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
function PolicyForm({ index, title, descAr, descEn, setDescAr, setDescEn }) {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);

  return (
    <div className="flex my-[20px]">
      <div>
        <div className="text-[#000] font-extrabold text-[14px]">{index}.</div>
      </div>
      <div className="ml-[6%] w-[87%]">
        <div className="w-[40%]">
          <input
            disabled
            className="w-full h-[30px] placeholder:font-arabic font-arabic rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[13px] text-[13px] placeholder:font-[400] font-[400]"
            placeholder=""
            value={title}
            type="text"
            id="branch-name"
          />
        </div>
        <div className="flex mt-[10px] flex-wrap justify-between w-full">
          {descAr !== false && (
            <RichTextEditor
              name="Description-AR"
              initialValue={descAr}
              getValue={setDescAr}
            />
          )}
          {descAr === false && (
            <RichTextEditor
              name="Description-AR"
              initialValue=""
              getValue={setDescAr}
            />
          )}
          {descEn !== false && (
            <RichTextEditor
              name="Description-EN"
              initialValue={descEn}
              getValue={setDescEn}
            />
          )}
          {descEn === false && (
            <RichTextEditor
              name="Description-EN"
              initialValue=""
              getValue={setDescEn}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PolicyForm;
