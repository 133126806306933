import React, { useEffect, useState } from "react";
import FileMultiUpload from "../FileUploader/FileMultiUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Loading from "../Loading";
import NetworkError from "../Modals/NetworkError";
import Swal from 'sweetalert2'
function WhoWeArePage() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  //Image Slider
  const [sliderFiles, setSliderFiles] = useState([]);
  const [sliderFilesData, setSliderFilesData] = useState([]);
  const removeSliderFile = (filename, index) => {
    setSliderFiles(sliderFiles.filter((file, i) => i !== index));
    setSliderFilesData(sliderFilesData.filter((fileData, i) => i !== index));
  };

  //Quality Certificates
  const [certifFiles, setCertifFiles] = useState([]);
  const [certifFilesData, setCertifFilesData] = useState([]);
  const removeCertifFile = (filename, index) => {
    setCertifFiles(certifFiles.filter((file, i) => i !== index));
    setCertifFilesData(certifFilesData.filter((fileData, i) => i !== index));
  };

  const [introAr, setIntroAr] = useState(false);
  const [introEn, setIntroEn] = useState(false);

  const [ourValueAr, setOurValueAr] = useState(false);
  const [ourValueEn, setOurValueEn] = useState(false);

  const [ourGoalAr, setOurGoalAr] = useState(false);
  const [ourGoalEn, setOurGoalEn] = useState(false);

  const [directorWordAr, setDirectorWordAr] = useState(false);
  const [directorWordEn, setDirectorWordEn] = useState(false);

  //NEW FILES
  const [newSlider, setNewSlider] = useState();
  const [newCertif, setNewCertif] = useState();

  // DATABASE FILES

  const [media, setMedia] = useState(null);
  const fetchMedia = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/pages/getall/who_we_are`
    );
    const data = response.data;
    setIntroAr(data.firstpart[0].intro_ar);
    setIntroEn(data.firstpart[0].intro_en);
    setOurValueAr(data.firstpart[0].our_value_ar);
    setOurValueEn(data.firstpart[0].our_value_en);
    setOurGoalAr(data.firstpart[0].our_goal_ar);
    setOurGoalEn(data.firstpart[0].our_goal_en);
    setDirectorWordAr(data.firstpart[0].word_director_ar);
    setDirectorWordEn(data.firstpart[0].word_director_en);
    setMedia(data);
  };

  useEffect(() => {
    fetchMedia();
  }, []);

  const [databaseSlider, setDatabaseSlider] = useState([]);
  const removeDatabaseSlider = (index) => {
    setDatabaseSlider(databaseSlider.filter((file, i) => i !== index));
  };

  const [databaseCertif, setDatabaseCertif] = useState([]);
  const removeDatabaseCertif = (index) => {
    setDatabaseCertif(databaseCertif.filter((file, i) => i !== index));
  };

  useEffect(() => {
    if (media !== null) {
      let slideritems = [];
      let certifitems = [];

      media.secondpart.map((item) => {
        if (item.categoryImage === "Who_we_are_page") {
          slideritems = [...slideritems, item.path_image];
        } else if (item.categoryImage === "Quality_certificates") {
          certifitems = [...certifitems, item.path_image];
        }
      });
      setDatabaseSlider(slideritems);
      setDatabaseCertif(certifitems);
    }
  }, [media]);

  // UPDATING DATA
  const uploadImageCategory = async (
    imageCategory,
    setNewImagecategory,
    setDatabaseImagecategory,
    uploadedFiles
  ) => {
    var databaseImages = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const formData = new FormData();
      formData.append("file", uploadedFiles[i], uploadedFiles[i].name);
      await axios
        .post(
          `${process.env.REACT_APP_API_PATH}/products/upload/product-image`,
          formData
        )
        .then((imgResponse) => {
          databaseImages = [...databaseImages, imgResponse.data.imagePath];
        })
        .catch((err) => {
          // inform the user
          console.log(err);
        });
    }
    setNewImagecategory([...imageCategory, ...databaseImages]);
    setDatabaseImagecategory([...imageCategory, ...databaseImages]);
  };

  const updateData = () => {
    setLoading(true);
    uploadImageCategory(
      databaseSlider,
      setNewSlider,
      setDatabaseSlider,
      sliderFiles
    );
  };

  useEffect(() => {
    if (newSlider) {
      uploadImageCategory(
        databaseCertif,
        setNewCertif,
        setDatabaseCertif,
        certifFiles
      );
    }
  }, [newSlider]);

  useEffect(() => {
    if (newCertif) {
      uploadAll();
    }
  }, [newCertif]);

  const uploadAll = async () => {
    setSliderFiles([]);
    setCertifFiles([]);
    await axios
      .post(`${process.env.REACT_APP_API_PATH}/pages/who_we_are/create`, {
        image_url: newSlider,
        certificats_urls: newCertif,
        intro_description_en: introEn,
        intro_description_ar: introAr,
        ourvalue_des_ar: ourValueAr,
        ourvalue_des_en: ourValueEn,
        ourgoal_des_ar: ourGoalAr,
        ourgoal_des_en: ourGoalEn,
        director_general_word_ar: directorWordAr,
        director_general_word_en: directorWordEn,
      })
      .then(function (response) {
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Your changes had been saved',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <div>
      <div className="flex">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">1.</div>
        </div>
        <div className="ml-[6%]">
          <p className="text-[#000] font-[700] text-[14px]">Page slider</p>
          <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Select images for the slider ( JPEG or PNG ):
            <span className="text-[red] font-[700]">*</span>
          </p>
          <div className=" w-[130px] h-[30px] mt-[10px]">
            <FileMultiUpload
              files={sliderFiles}
              filesData={sliderFilesData}
              setFilesData={setSliderFilesData}
              setFiles={setSliderFiles}
              removeFile={removeSliderFile}
            />
          </div>
          <div>
            <FileList
              removeDatabaseFile={removeDatabaseSlider}
              databaseFiles={databaseSlider}
              files={sliderFiles}
              filesData={sliderFilesData}
              removeFile={removeSliderFile}
            />
          </div>
        </div>
      </div>
      <div className="flex mt-[40px]">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">2.</div>
        </div>
        <div className="ml-[6%] w-[87%]">
          <p className="text-[#000] font-[700] text-[14px]">Content</p>
          <div className=" mt-[20px] text-[14px]">Intro</div>
          <div className="flex flex-wrap justify-between w-full">
            {introAr !== false && (
              <RichTextEditor
                name="Intro-AR"
                initialValue={introAr}
                getValue={setIntroAr}
              />
            )}
            {introEn !== false && (
              <RichTextEditor
                name="Intro-EN"
                initialValue={introEn}
                getValue={setIntroEn}
              />
            )}
          </div>
          <div className=" mt-[20px] text-[14px]">Our Value</div>
          <div className="flex flex-wrap justify-between w-full">
            {ourValueAr !== false && (
              <RichTextEditor
                name="Our Value-Ar"
                initialValue={ourValueAr}
                getValue={setOurValueAr}
              />
            )}

            {ourValueEn !== false && (
              <RichTextEditor
                name="Our Value-EN"
                initialValue={ourValueEn}
                getValue={setOurValueEn}
              />
            )}
          </div>
          <div className=" mt-[20px] text-[14px]">Our Goal</div>
          <div className="flex flex-wrap justify-between w-full">
            {ourGoalAr !== false && (
              <RichTextEditor
                name="Our Goal-AR"
                initialValue={ourGoalAr}
                getValue={setOurGoalAr}
              />
            )}
            {ourGoalEn !== false && (
              <RichTextEditor
                name="Our Goal-EN"
                initialValue={ourGoalEn}
                getValue={setOurGoalEn}
              />
            )}
          </div>
          <div className=" mt-[20px] text-[14px]">
            A word From the Director-General
          </div>
          <div className="flex flex-wrap justify-between w-full">
            {directorWordAr !== false && (
              <RichTextEditor
                name="Director Word-AR"
                initialValue={directorWordAr}
                getValue={setDirectorWordAr}
              />
            )}
            {directorWordEn !== false && (
              <RichTextEditor
                name="Director Word-EN"
                initialValue={directorWordEn}
                getValue={setDirectorWordEn}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex mt-[40px]">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">3.</div>
        </div>
        <div className="ml-[6%]">
          <p className="text-[#000] font-[700] text-[14px]">
            Quality Certificates
          </p>
          <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Select images for the certificates ( JPEG or PNG ):
            <span className="text-[red] font-[700]">*</span>
          </p>
          <div className=" w-[130px] h-[30px] mt-[10px]">
            <FileMultiUpload
              files={certifFiles}
              filesData={certifFilesData}
              setFilesData={setCertifFilesData}
              setFiles={setCertifFiles}
              removeFile={removeCertifFile}
            />
          </div>
          <div>
            <FileList
              removeDatabaseFile={removeDatabaseCertif}
              databaseFiles={databaseCertif}
              files={certifFiles}
              filesData={certifFilesData}
              removeFile={removeCertifFile}
            />
          </div>
        </div>
      </div>
      {loading && <Loading />}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="flex pb-[10px] float-right mr-[80px] mt-[20px]">
        <div className="cursor-pointer rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
          Cancel
        </div>
        <div
          onClick={updateData}
          className="cursor-pointer rounded-full cursor-pointer text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
        >
          Save
        </div>
      </div>
    </div>
  );
}

export default WhoWeArePage;
