import React, { useState, useEffect } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import SettingsTab from "../SettingsTab";
import PolicyForm from "../PolicyForm";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/settingsTabs.module.css";
import { selectProduct } from "../../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import Images from "../ProductSettings/Images";
import PDF from "../ProductSettings/PDF";
import Video from "../ProductSettings/Video";
import SKU from "../ProductSettings/SKU";
import Manufacturers from "../ProductSettings/Manufacturers";
import axios from "axios";
import Loading from "../Loading.js";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import NetworkError from "../../components/Modals/NetworkError";
import Swal from "sweetalert2";
function EditProduct() {
  const [showError, setShowError] = useState(false);
  const selectedProduct = useSelector(selectProduct);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //GETTING ALL THE CATEGORIES
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/categories/findall`
    );
    setCategories(response.data);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // UPLOADED FILES AND INPUTS

  const [files, setFiles] = useState([]);

  const [pdf, setPdf] = useState([]);

  const [videoUrl, setVideoUrl] = useState("");

  const [sku, setSku] = useState([]);
  const [skuCode, setSkuCode] = useState("");

  const [manufacturers, setManufacturers] = useState("");

  const [productCategory, setProductCategory] = useState("");

  const [descAr, setDescAr] = useState(false);

  const [descEn, setDescEn] = useState(false);

  const [values, setValues] = useState({});

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Database Files
  const [databaseImages, setDatabaseImages] = useState([]);
  const removeDatabaseImage = (index) => {
    setDatabaseImages(databaseImages.filter((file, i) => i !== index));
  };

  const [databasePdf, setDatabasePdf] = useState([]);
  const removeDatabasePdf = (index) => {
    setDatabasePdf([]);
  };
  const [databaseSku, setDatabaseSku] = useState([]);
  const removeDatabaseSku = (index) => {
    setDatabaseSku(databaseSku.filter((file, i) => i !== index));
  };

  const [product, setProduct] = useState({});

  const fetchProduct = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/products/${selectedProduct.id}`
    );
    const data = response.data;
    setValues({
      nameEn: data.product_translations[0].nameProduct,
      nameAr: data.product_translations[1].nameProduct,
      category: data.category_id,
      price: data.price,
      points: data.points,
      reviews: data.product_review,
      quantity: data.stock,
      points: data.points,
    });
    setSkuCode(data.sku_code);
    setVideoUrl(data.video_url);
    setManufacturers(data.manufacter);
    setDescAr(data.product_translations[1].description);
    setDescEn(data.product_translations[0].description);
    setDatabasePdf([data.product_pdf]);
    setDatabaseSku([data.sku]);
    setProductCategory(data.category_id);
    let images = [];
    data.product_images.map((item) => {
      images = [...images, item.image_url];
    });
    setDatabaseImages(images);
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  //NEW FILES
  const [newImages, setNewImages] = useState();
  const [newPdf, setNewPdf] = useState();
  const [newSku, setNewSku] = useState();

  const uploadImageCategory = async (
    imageCategory,
    setNewImagecategory,
    setDatabaseImagecategory,
    uploadedFiles
  ) => {
    var databaseImages = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const formData = new FormData();
      formData.append("file", uploadedFiles[i], uploadedFiles[i].name);
      await axios
        .post(
          `${process.env.REACT_APP_API_PATH}/products/upload/product-image`,
          formData
        )
        .then((imgResponse) => {
          databaseImages = [...databaseImages, imgResponse.data.imagePath];
        })
        .catch((err) => {
          // inform the user
          console.log(err);
        });
    }
    setNewImagecategory([...imageCategory, ...databaseImages]);
    setDatabaseImagecategory([...imageCategory, ...databaseImages]);
  };

  const uploadImages = (e) => {
    e.preventDefault();
    if (
      (files.length !== 0 || databaseImages.length !== 0) &&
      (sku.length !== 0 || databaseSku.length !== 0) &&
      values.nameAr !== "" &&
      values.nameEn !== "" &&
      values.price !== "" &&
      descEn !== "" &&
      descAr !== "" &&
      skuCode !== ""
    ) {
      setError(null);
      setLoading(true);
      uploadImageCategory(
        databaseImages,
        setNewImages,
        setDatabaseImages,
        files
      );
    } else {
      setError("Please fill all the required fields.");
    }
  };

  useEffect(() => {
    if (newImages) {
      uploadPdf();
    }
  }, [newImages]);

  useEffect(() => {
    if (newPdf) {
      uploadSku();
    }
  }, [newPdf]);

  useEffect(() => {
    if (newSku) {
      uploadAll();
    }
  }, [newSku]);

  const uploadPdf = async () => {
    if (pdf.length > 0) {
      for (let i = 0; i < pdf.length; i++) {
        const formData = new FormData();
        formData.append("file", pdf[i], pdf[i].name);
        await axios
          .post(
            `${process.env.REACT_APP_API_PATH}/products/upload/product-pdf`,
            formData
          )
          .then((imgResponse) => {
            setNewPdf([imgResponse.data.imagePath]);
            setDatabasePdf([imgResponse.data.imagePath]);
          })
          .catch((err) => {
            // inform the user
            console.log(err);
          });
      }
    } else {
      setNewPdf([...databasePdf]);
    }
    setPdf([]);
  };
  const uploadSku = async () => {
    if (sku.length > 0) {
      for (let i = 0; i < sku.length; i++) {
        const formData = new FormData();
        formData.append("file", sku[i], sku[i].name);
        await axios
          .post(
            `${process.env.REACT_APP_API_PATH}/products/upload/product-sku`,
            formData
          )
          .then((imgResponse) => {
            setNewSku([imgResponse.data.imagePath]);
            setDatabaseSku([imgResponse.data.imagePath]);
          })
          .catch((err) => {
            // inform the user
            console.log(err);
          });
      }
    } else {
      setNewSku([...databaseSku]);
    }
    setSku([]);
  };

  const uploadAll = async () => {
    setFiles([]);
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}/products/update/${selectedProduct.id}`,
        {
          name_en: values.nameEn,
          name_ar: values.nameAr,
          description_en: descEn,
          description_ar: descAr,
          category_id: parseInt(values.category),
          image_urls: newImages,
          video_url: videoUrl,
          sku: newSku[0],
          sku_code: skuCode,
          stock: parseInt(values.quantity),
          points: parseInt(values.points),
          price: parseInt(values.price),
          fees_tax: false,
          product_review: values.reviews,
          product_pdf: newPdf[0] ? newPdf[0] : null,
          manufacter: manufacturers,
        }
      )
      .then(function (response) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <form onSubmit={uploadImages}>
      <div className="ml-[6%]">
        <div>
          <div className="flex flex-wrap">
            <div className="w-[45%] mt-2 mr-[20px]">
              <label className="text-[14px] font-[500]" htmlFor="name-en">
                Name - En <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                required
                className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                placeholder="Enter product name in english"
                onChange={changeHandler}
                value={values.nameEn}
                name="nameEn"
                type="text"
                id="name-en"
              />
            </div>
            <div className="w-[45%] mt-2 mr-[20px]">
              <label className="text-[14px] font-[500]" htmlFor="name-ar">
                Name - Ar <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                required
                className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                placeholder="Enter product name in arabic"
                onChange={changeHandler}
                value={values.nameAr}
                name="nameAr"
                type="text"
                id="name-ar"
              />
            </div>
            <div className="w-[45%] mt-4 mr-[20px]">
              <label className="text-[14px] font-[500]" htmlFor="category">
                Category <span className="text-[red] font-[700]">*</span>
              </label>
              <br />
              <select
                required
                value={values.category}
                name="category"
                onChange={changeHandler}
                className="w-full h-[30px] px-1 rounded border-[#c4c8cf] font-[400] text-[14px] text-[#000]"
              >
                <option value="">Choose a category</option>
                {categories.map((category) => (
                  <option
                    selected={productCategory === category.parent_id}
                    value={category.id}
                  >
                    {category..category_translations[1].name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[45%] mt-4 mr-[20px]">
              <label className="text-[14px] font-[500]" htmlFor="price">
                Price <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                required
                className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                placeholder="Enter product price"
                onChange={changeHandler}
                value={values.price}
                min="0"
                name="price"
                type="number"
                id="price"
              />
            </div>
            <div className="flex flex-wrap mt-4 w-[97.5%]">
              {descEn !== false && (
                <RichTextEditor
                  name="Description-EN"
                  initialValue={descEn}
                  getValue={setDescEn}
                />
              )}
              {descAr !== false && (
                <RichTextEditor
                  name="Description-AR"
                  initialValue={descAr}
                  getValue={setDescAr}
                />
              )}
            </div>
            <div className="w-[45%] mt-2 mr-[20px]">
              <label className="text-[14px] font-[500]" htmlFor="points">
                Points <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                required
                className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                placeholder="Enter product points"
                onChange={changeHandler}
                value={values.points}
                name="points"
                min="0"
                min="0"
                type="number"
                id="points"
              />
            </div>
            <div className="w-[45%] mt-2 mr-[20px]">
              <label className="text-[14px] font-[500]" htmlFor="points">
                Quantity <span className="text-[red] font-[700]">*</span>
              </label>
              <input
                required
                className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                placeholder="Enter the product initial quantity"
                onChange={changeHandler}
                value={values.quantity}
                name="quantity"
                min="0"
                type="number"
                id="quantity"
              />
            </div>
            <div className="w-[45%] mt-8 mr-[20px] flex">
              <div className="mr-[20px] text-[15px] font-[500] text-[#000]">
                Select Product Reviews
              </div>
              <div>
                <input
                  required
                  className="mr-[10px] focus:border-none focus:outline-none ml-[30px]"
                  type="radio"
                  name="reviews"
                  checked={values.reviews}
                  onChange={() => {
                    setValues({ ...values, reviews: true });
                  }}
                  value="on"
                  id="on"
                />
                <label htmlFor="on" className="text-[14px] font-[500]">
                  On
                </label>
                <input
                  required
                  className="mr-[10px] ml-[30px]"
                  type="radio"
                  checked={!values.reviews}
                  onChange={() => {
                    setValues({ ...values, reviews: false });
                  }}
                  name="reviews"
                  value="off"
                  id="off"
                />
                <label htmlFor="off" className="text-[14px] font-[500]">
                  Off
                </label>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <label className="text-[14px] font-[500]">
              Settings <span className="text-[red] font-[700]">*</span>
            </label>
            <div
              className={`${styles.tabs} flex justify-between w-[91%] rounded-xl`}
            >
              <Images
                removeDatabaseFile={removeDatabaseImage}
                databaseFile={databaseImages}
                files={files}
                setFiles={setFiles}
              />
              <PDF
                setDatabaseFile={setDatabasePdf}
                removeDatabaseFile={removeDatabasePdf}
                databaseFile={databasePdf}
                pdf={pdf}
                setPdf={setPdf}
              />
              <Video videoUrl={videoUrl} setVideoUrl={setVideoUrl} />
              <SKU
                setDatabaseFile={setDatabaseSku}
                removeDatabaseFile={removeDatabaseSku}
                databaseFile={databaseSku}
                sku={sku}
                setSku={setSku}
                skuCode={skuCode}
                setSkuCode={setSkuCode}
              />
              <Manufacturers
                manufacturers={manufacturers}
                setManufacturers={setManufacturers}
              />
            </div>
          </div>
        </div>
        {loading ? <Loading /> : null}
        {showError ? <NetworkError setShowModal={setShowError} /> : null}
        {error ? (
          <div>
            <p className="text-[14px] ml-2 mt-[220px] text-[red]">
              <FontAwesomeIcon icon={faCircleExclamation} /> {error}
            </p>
          </div>
        ) : null}
        <div className="absolute flex pb-[10px] right-[80px] bottom-[-254px]">
          <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
            Cancel
          </div>
          <input
            type="submit"
            className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
}

export default EditProduct;
