const style = {
  border: "none",
  color: "#033362",
  fontSize: "14px",
  fontWeight: "lighter",
};

const columns = [
  {
    name: "id",
    header: <h3 style={{ fontWeight: "700" }}>ID</h3>,
    defaultFlex: 0.5,
    type:'number',
    headerProps: { style: style },
    className: "text-[14px] font-[400] text-[#0388CC]",
  },
  {
    name: "offerName",
    header: <h3 style={{ fontWeight: "700" }}>Offer Name</h3>,
    defaultFlex: 1.2,
    headerProps: { style: style },
    className: "text-[14px] font-[400] text-[#0388CC]",
  },
  {
    name: "categories",
    header: <h3 style={{ fontWeight: "700", fontFamily:'Poppins' }}>Categories</h3>,
    defaultFlex: 2,
    headerProps: { style: style },
    className: "text-[14px] font-[400] font-arabic w-fit px-[5px] font-[400]",
  },
  {
    name: "value",
    header: <h3 style={{ fontWeight: "700" }}>Value</h3>,
    defaultFlex: 0.7,
    type:'number',
    headerProps: { style: style },
    className:"text-[14px] font-[400] text-[#0388CC]"
  },
  {
    name: "creationDate",
    header: <h3 style={{ fontWeight: "700" }}>Creation Date</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "expirationDate",
    header: <h3 style={{ fontWeight: "700" }}>Expiration Date</h3>,
    defaultFlex: 1,
    headerProps: { style: style },
    className:"font-[400]"
  },
  {
    name: "status",
    header: <h3 style={{ fontWeight: "700" }}>Status</h3>,
    defaultFlex: 0.6,
    headerProps: { style: style },
  },
  {
    name: "view",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 0.5,
    headerProps: { style: style },
  },
  {
    name: "delete",
    header: <h3 style={{ fontWeight: "700" }}></h3>,
    defaultFlex: 0.5,
    headerProps: { style: style },
  },
];

export default columns;
