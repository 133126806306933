import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../ContactForm";
import { selectProduct } from "../../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import AddFeatureType from "../Modals/AddFeatureType";
import NetworkError from "../Modals/NetworkError";
import Loading from "../Loading";
import Swal from "sweetalert2";
function Features() {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const selectedProduct = useSelector(selectProduct);
  const [values, setValues] = useState({
    priceAdded: "",
    value: "",
    feature: "",
  });
  const [features, setFeatures] = useState([]);
  const [productFeatures, setProductFeatures] = useState([]);

  const fetchFeatures = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/products/features`
    );
    const data = response.data;
    if (data.length !== features.length) {
      setFeatures(data);
    }
  };

  const fetchProductFeatures = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/products/feature/${selectedProduct.id}`
    );
    const data = response.data;
    setProductFeatures(data);
  };

  useEffect(() => {
    fetchFeatures();
  }, []);

  useEffect(() => {
    fetchProductFeatures();
  }, []);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const findNames = (id) => {
    var names = [];
    features.forEach((element) => {
      if (element.id === parseInt(id)) {
        names = [element.name_ar, element.name_en];
      }
    });
    return names;
  };

  const addFeature = async (e) => {
    e.preventDefault();
    setLoading(true);
    const names = findNames(values.feature);
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}/products/feature/create/${selectedProduct.id}`,
        {
          feature_name_en: names[1],
          feature_name_ar: names[0],
          value: values.value,
          price_added: parseInt(values.priceAdded),
        }
      )
      .then(function (response) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
    fetchProductFeatures();
  };

  return (
    <div className="ml-[6%] flex mt-8">
      <form onSubmit={addFeature}>
        <div className="mr-[100px]">
          <div className="text-[14px] font-[400] text-[#000]">
            Add or Remove Stocks for this Product
          </div>
          <div className="text-[#000] text-[14px]  font-[400]">
            <div className="mt-4">
              <input
                required
                className="h-[30px] mr-6 mt-[5px] rounded-md w-[90%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Enter the value"
                onChange={changeHandler}
                value={values.value}
                name="value"
                type="text"
              />{" "}
            </div>
            <div className="mt-4">
              <input
                required
                className="h-[30px] mr-6 mt-[5px] rounded-md w-[90%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                placeholder="Price added"
                onChange={changeHandler}
                value={values.priceAdded}
                name="priceAdded"
                type="text"
              />
            </div>
            <div className="mt-4">
              <select
                required
                value={values.feature}
                onChange={changeHandler}
                name="feature"
                className="w-[200px] h-[30px] mt-1 rounded-md px-1 font-[400] text-[14px] text-[#000]"
              >
                <option value="">Select a feature</option>
                {features.map((feature) => (
                  <option value={feature.id}>{feature.name_en}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="border-[#0388CC] w-fit px-4 cursor-pointer rounded-md border-[1px] text-[14px] mt-8 font-[400] h-[30px] flex items-center text-[#0388CC]">
            <span className="mr-2 text-[15px]">
              <FontAwesomeIcon icon={faCirclePlus} />
              <input
                type="submit"
                className="cursor-pointer "
                value="Add new feature"
              />
            </span>{" "}
          </div>
          <div
            onClick={() => {
              setShowModal(true);
            }}
            className="bg-[#0388CC] w-fit px-4 cursor-pointer rounded-md border-[1px] text-[14px] mt-16 font-[400] h-[30px] flex items-center text-[#fff]"
          >
            <span className="mr-2 text-[15px]">
              <FontAwesomeIcon icon={faCirclePlus} />
            </span>{" "}
            Add new feature type
          </div>
          {showModal ? (
            <AddFeatureType
              setShowModal={setShowModal}
              features={features}
              setFeatures={setFeatures}
            />
          ) : null}
          <div className="absolute flex pb-[10px] right-[80px] bottom-[-0px]">
            <div className="rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
              Cancel
            </div>
            <div className="rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]">
              Save
            </div>
          </div>
        </div>
      </form>
      {loading ? <Loading /> : null}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="text-[16px] font-[400] text-[#000]">
        <div className="text-[16px] font-[600]">Product features</div>
        {productFeatures.map((feature) => (
          <div className="flex p-2">
            <div className="mr-2">{feature.feature_translations[0].name}:</div>
            <div className="flex">
              {feature.feature_values.map((value) => (
                <div className="flex border-[1px] rounded border-[#c4c8cf] mr-2">
                  <div className="px-2">{value.value}</div>{" "}
                  <div className="px-2 border-l-[2px]">08$</div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {productFeatures.length === 0 && (
          <p className="mt-2 text-[14px]">This product has no features yet.</p>
        )}
      </div>
    </div>
  );
}

export default Features;
