import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import "./FileUpload.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
const FileMultiUpload = ({
  files,
  setFiles,
  removeFile,
  filesData,
  setFilesData,
  required,
}) => {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);

  const uploadHandler = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;
    setFiles([...files, file]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setFilesData([...filesData, reader.result]);
    });
    reader.readAsDataURL(event.target.files[0]);

    // upload file
    const formData = new FormData();
    formData.append("newFile", file, file.name);
  };

  return (
    <>
      <div className="file-card w-full h-full">
        <div className="file-inputs">
          <input
            required={required}
            accept="image/*"
            disabled={disabled}
            onClick={(event) => {
              event.target.value = null;
            }}
            type="file"
            onChange={uploadHandler}
          />
          <button>
            <i>
              <FontAwesomeIcon icon={faArrowUpFromBracket} />
            </i>
            Upload
          </button>
        </div>
      </div>
    </>
  );
};

export default FileMultiUpload;
