import React from "react";

function Notification({ text }) {
  return (
    <div className="text-[13px] w-[95%] mt-[10px] font-[400] rounded-md px-[10px] py-[10px] bg-[#F4FBFF]">
      {text}
    </div>
  );
}

export default Notification;
