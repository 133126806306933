import React, { useEffect, useState } from "react";
import { faBell, faComment, faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/jobsManagementColumns";
import NewJob from "../Modals/NewJob";
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import JobStatus from "../JobStatus";
import { Link } from "react-router-dom";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
import DeleteWarning from "../Modals/DeleteWarning";
import { selectUser } from "../../redux/slices/userSlice";
function JobsManagement() {
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser.user.[1];
  const disabled = !["HR", "Admin"].includes(adminRole);
  const dispatch = new useDispatch();
  const searchInput = useSelector(selectSearch);
  const [showModal, setShowModal] = React.useState(false);

  const [jobs, setJobs] = useState([]);

  const fetchJobs = async () => {
    await axios
      .get(process.env.REACT_APP_API_PATH + "/careers/jobmanagement/findall")
      .then((res) => {
        setJobs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(jobs);
  }, [jobs]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        jobs.filter((job) => {
          return job.name_ar.includes(searchInput);
        })
      );
    } else {
      setFilteredData(jobs);
    }
  }, [searchInput]);

  var data = [];

  for (let i = 0; i < filteredData.length; i++) {
    const job = filteredData[i];
    data = [
      ...data,
      {
        id: job.id,
        jobPosition: job.name_ar,
        location: job.location,
        lastUpdate: job.updatedAt.slice(0, 10),
        status: <JobStatus status={job.active} />,
 
        view: (
          <Link
            to={!disabled ? `/job-position-edit?id=${job.id}` : "/careers?tab=2"}
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
          onClick={() => {
            if (!disabled) {
              setItemToDelete({ id: job.id, index: i });
              setShowWarningModal(true);
            }
          }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  return (
    <div className="flex flex-col justify-between">
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewJob setShowModal={setShowModal} jobs={jobs} setJobs={setJobs} />
      ) : null}
      <div
        onClick={() => setShowModal(true)}
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[0px] ml-[20px] text-[13px]"
      >
        <div className="mr-2">Add new job </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
      {showWarningModal ? (
        <DeleteWarning
          url="/careers/jobmanagement/delete/"
          setShowModal={setShowWarningModal}
          itemToDelete={itemToDelete}
          items={jobs}
          setItems={setJobs}
        />
      ) : null}
    </div>
  );
}

export default JobsManagement;
