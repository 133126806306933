import React, { useState } from "react";
import axios from "axios";
import FileUpload from "../FileUploader/FileUpload";
import FileItem from "../FileUploader/FileItem";
import RichTextEditor from "../RichTextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Loading";
import NetworkError from "./NetworkError";
import Swal from "sweetalert2";
function NewCategory({ setShowModal, setCategories, categories }) {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //Category Image
  const [categoryImage, setCategoryImage] = useState([]);
  const [categoryImageData, setCategoryImageData] = useState([]);
  const removeCategoryImage = (filename, index) => {
    setCategoryImage([]);
    setCategoryImageData([]);
  };

  //Category Icon
  const [categoryIcon, setCategoryIcon] = useState([]);
  const [categoryIconData, setCategoryIconData] = useState([]);
  const removeCategoryIcon = (filename, index) => {
    setCategoryIcon([]);
    setCategoryIconData([]);
  };

  const [values, setValues] = useState({
    nameEn: "",
    nameAr: "",
    parent: "",
  });
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitCategory = async (e) => {
    e.preventDefault();
    if (
      values.nameAr !== "" &&
      values.nameEn !== "" &&
      categoryImage.length !== 0 &&
      categoryIcon.length !== 0
    ) {
      setError(null);
      setLoading(true);
      const formData = new FormData();
      formData.append("file", categoryImage[0], categoryImage[0].name);
      await axios
        .post(
          `${process.env.REACT_APP_API_PATH}/categories/upload/category-image`,
          formData
        )
        .then((imgResponse) => {
          const formData2 = new FormData();
          formData2.append("file", categoryIcon[0], categoryIcon[0].name);
          axios
            .post(
              `${process.env.REACT_APP_API_PATH}/categories/upload/category-image`,
              formData2
            )
            .then((iconResponse) => {
              axios
                .post(`${process.env.REACT_APP_API_PATH}/categories/create`, {
                  image_url: imgResponse.data.imagePath,
                  icon_url: iconResponse.data.imagePath,
                  parent_id: parseInt(values.parent),
                  name_en: values.nameEn,
                  name_ar: values.nameAr,
                })
                .then(function (response) {
                  setCategories([...categories, response.data]);
                  setLoading(false);
                  setShowModal(false);
                  Swal.fire({
                    icon: "success",
                    title: "Your changes had been saved",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              // inform the user
              console.error(err);
            });
        })
        .catch((err) => {
          // inform the user
          if (err.code === "ERR_NETWORK") {
            setLoading(false);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
          }
        });
    } else {
      setError("Please fill all required fields");
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[85%]">
          <form onSubmit={submitCategory}>
            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between h-[50px]  rounded-t">
                <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                  Add New Category
                </div>
                <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
              </div>
              {/* {body} */}
              <div className="relative p-6 w-full ">
                <div className="">
                  <div className="w-[90%] mr-[120px] my-[10px]">
                    <label className="text-[14px] font-[500]" htmlFor="name-en">
                      Name - En <span className="text-[red] font-[700]">*</span>
                    </label>
                    <input
                      required
                      className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                      placeholder="Enter category name in english"
                      onChange={changeHandler}
                      value={values.nameEn}
                      name="nameEn"
                      type="text"
                      id="name-en"
                    />
                  </div>
                  <div className="w-[90%] mr-[120px] my-[10px]">
                    <label className="text-[14px] font-[500]" htmlFor="name-ar">
                      Name - Ar <span className="text-[red] font-[700]">*</span>
                    </label>
                    <input
                      required
                      className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                      placeholder="Enter category name in arabic"
                      value={values.nameAr}
                      onChange={changeHandler}
                      name="nameAr"
                      type="text"
                      id="name-ar"
                    />
                  </div>
                  <div className="">
                    <p className="text-[14px] font-[500]">Parents</p>
                    <select
                      value={values.parent}
                      name="parent"
                      onChange={changeHandler}
                      className="w-[50%] px-1 rounded font-[400] text-[14px] text-[#000]"
                    >
                      <option value={null}>No parent</option>
                      {categories.map((category) => (
                        <option value={category.id}>
                          {category..category_translations[1].name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="">
                  <div>
                    <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
                      <span className="font-[500]">Image</span> : Thumbnails
                      will be generated from detailed image automatically ,but
                      you can also{" "}
                      <span className="text-[#2685CC]">
                        upload them manually
                      </span>
                    </p>
                    <p className="text-[#000] text-[14px] font-[400]">
                      Select a images of Product ( JPEG or PNG ):
                      <span className="text-[red] font-[700]">*</span>
                    </p>
                    <div className=" w-[130px] h-[30px] mt-[5px]">
                      <FileUpload
                        required={true}
                        files={categoryImage}
                        setFiles={setCategoryImage}
                        removeFile={removeCategoryImage}
                        setFileData={setCategoryImageData}
                      />
                    </div>
                    <FileItem
                      fileData={categoryImageData}
                      file={categoryImage.[0]}
                      removeFile={removeCategoryImage}
                      index="0"
                    />
                  </div>

                  <div className="mt-10">
                    <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
                      <span className="font-[500]">Icon</span> : Thumbnails will
                      be generated from detailed image automatically ,but you
                      can also{" "}
                      <span className="text-[#2685CC]">
                        upload them manually
                      </span>
                    </p>
                    <p className="text-[#000] text-[14px] font-[400]">
                      Select a images of Product ( JPEG or PNG ):
                      <span className="text-[red] font-[700]">*</span>
                    </p>
                    <div className=" w-[130px] h-[30px] mt-[5px]">
                      <FileUpload
                        required={true}
                        files={categoryIcon}
                        setFiles={setCategoryIcon}
                        removeFile={removeCategoryIcon}
                        setFileData={setCategoryIconData}
                      />
                    </div>
                    <FileItem
                      fileData={categoryIconData}
                      file={categoryIcon.[0]}
                      removeFile={removeCategoryIcon}
                      index="0"
                    />
                  </div>
                </div>
                {error ? (
                  <p className="text-[14px] mt-2 text-[red]">
                    <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                  </p>
                ) : null}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-3 rounded-b">
                <div className=" flex pb-[10px] ">
                  <div
                    onClick={() => setShowModal(false)}
                    className="rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                  >
                    Cancel
                  </div>
                  <input
                    type="submit"
                    className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                    value="Save"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading ? <Loading /> : null}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default NewCategory;
