/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/selector.module.css";
import axios from "axios";

const optionss = {
  Admin: {
    title: "Admin",
    color: "text-[#007530]",
    bgColor: "bg-[#007530]",
  },
  HR: {
    title: "HR",
    color: "text-[#ff9900]",
    bgColor: "bg-[#ff9900]",
  },
  Sales: {
    title: "Sales",
    color: "text-[#4a86e8]",
    bgColor: "bg-[#4a86e8]",
  },
  Messaging: {
    title: "Messaging",
    color: "text-[#e06666]",
    bgColor: "bg-[#e06666]",
  },
  DataEntry: {
    title: "DataEntry",
    color: "text-[#cc4125]",
    bgColor: "bg-[#cc4125]",
  },
};

const options = [
  {
    title: "Admin",
    value: "0",
    color: "text-[#007530]",
    bgColor: "bg-[#007530]",
  },
  {
    title: "HR",
    value: "1",
    color: "text-[#ff9900]",
    bgColor: "bg-[#ff9900]",
  },
  {
    title: "Sales",
    value: "3",
    color: "text-[#4a86e8]",
    bgColor: "bg-[#4a86e8]",
  },
  {
    title: "Messaging",
    value: "3",
    color: "text-[#e06666]",
    bgColor: "bg-[#e06666]",
  },
  {
    title: "DataEntry",
    value: "3",
    color: "text-[#cc4125]",
    bgColor: "bg-[#cc4125]",
  },
];


function UserRole({ role, userId, disabled }) {
  const [userRole, setUserRole] = useState(role);
  const handleChange = async (event) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}/users/changerole/${userId}/${event.target.value}`
      )
      .then(setUserRole(event.target.value));
  };

  return (
    <div
      className={`${styles.selectBox} ${optionss[userRole].bgColor} min-w-[50px]`}
    >
      <select
        disabled={disabled}
        value={userRole}
        onChange={(e) => {
          handleChange(e);
        }}
        className={`${optionss[userRole].bgColor} pl-2 outline-none cursor-pointer font-sans rounded py-[3px]  border-none text-[white] w-[100%] `}
      >
        {options.map((option, index) => (
          <option
            // selected={option.title === userRole}
            value={option.title}
            key={index}
            className={`${option.color}  outline-[0px] hover:bg-[white] bg-[white] py-[4px] rounded`}
          >
            {option.title.charAt(0).toLocaleUpperCase() + option.title.slice(1)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default UserRole;
