import React, { useState } from "react";
import styles from "../../styles/settingsTabs.module.css";

import FileUpload from "../FileUploader/FileUpload";
import FileItem from "../FileUploader/FileItem";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

function PDF({ pdf, setPdf, databaseFile, removeDatabaseFile, setDatabaseFile }) {
  const [pdfData, setPdfData] = useState(null);

  const removeFile = (filename) => {
    setPdf(null);
  };
  return (
    <div key={22} className={`${styles.tab} flex items-center`}>
      <input
        value="22"
        type="radio"
        name="settings-tabs"
        id="22"
        className={`${styles.tabSwicth}`}
      />
      <label htmlFor="22" id="22" className={`${styles.tabLabel}`}>
        <span className={`${styles.tabSpan} font-[400] text-[14px]`}>PDF</span>
      </label>
      <div className={`${styles.tabContent}`}>
        <span>
          <div>
            <p className="text-[#000] text-[14px] font-[400]">
              Select a PDF of Product:
            </p>
            <div className=" w-[130px] h-[30px] mt-[10px]">
              <FileUpload
              setDatabaseFile={setDatabaseFile}
                files={pdf}
                type="application/pdf"
                setFiles={setPdf}
                setFileData={setPdfData}
                removeFile={removeFile}
                required={false}
              />
            </div>
            <div className>
              <FileItem
                removeDatabaseFile={removeDatabaseFile}
                databaseFile={databaseFile}
                fileData={null}
                type="pdf"
                file={pdf.[0]}
                removeFile={removeFile}
                index="0"
              />
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}

export default PDF;
